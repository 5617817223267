import React from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import {
  getCollection,
  getUserCollections,
  getNftsInCollection,
  addNFtInCollection,
} from "@actions/collection"
import { mintNFT, getCosts } from "@actions/mintNft"
import { getBalance } from "@actions/user"
import { StickyContainer, Sticky } from "react-sticky"
import { useTranslation } from "react-i18next"
import { Layout } from "@bw/layouts"
import { NftTeaser, BecomeAnArtist } from "@bw/partials"
import {
  Loader,
  Section,
  Grid,
  Button,
  KeyValue,
  Avatar,
  Image,
} from "@bw/bits"
import { breakpoints } from "../../theme"
import { Select, FormField, Input, FileInput, TextArea } from "@bw/forms"
import { Formik, Form, Field, FieldArray } from "formik"
import { useUser } from "@contexts/user"
import { Creator } from "../../utils/oyster/common/src/index.tsx"
import { useQuery } from "react-query"
import * as yup from "yup"
const { PublicKey } = require("@solana/web3.js")

const placeholder = "█"
const transparentPixel =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

const PlaceHolder = styled.span`
  letter-spacing: -1px;
  line-break: anywhere;
  user-select: none;
`

const GridContainer = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 320px 1fr;
    column-gap: 230px;
  }
`

const validationSchemaStep = [
  yup.object().shape({
    collectionAddress: yup.string().required(),
    collection: yup.object().shape({
      name: yup.string(),
      family: yup.string(),
    }),
  }),
  yup.object().shape({
    name: yup.string().required(),
    symbol: yup.string().min(2).max(10).required(),
    description: yup.string().required(),
  }),
  yup.object().shape({
    image: yup
      .mixed()
      .required()
      .test(
        "image-is-big-enough",
        "Image size must be over 25KB",
        value => value?.size / 1024 > 25
      )
      .test(
        "image-is-too-big",
        "Image size must be under 9MB",
        value => value?.size / 1024 / 1024 < 8
      ),
    animation_url: yup.string().url(),
    external_url: yup.string().url().nullable(),
  }),
  yup.object().shape({
    attributes: yup.array().of(
      yup.object().shape({
        trait_type: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  }),
  yup.object().shape({
    creators: yup
      .array()
      .of(
        yup.object().shape({
          address: yup
            .string()
            .required()
            .test("is-valid-public-key", "Invalid Address", value => {
              try {
                new PublicKey(value)
                return true
              } catch (err) {
                return false
              }
            }),
          share: yup.number().required().min(0).max(100),
        })
      )
      .test("share-equals-hundred", "Must be 100", value => {
        const sum = value.reduce((acc, item) => acc + item.share, 0)
        return sum === 100
      })
      .min(1)
      .max(4)
      .required(),
    sellerFeeBasisPoints: yup.number().required().min(0).max(100),
  }),
  null,
]

const fullValidationSchema = validationSchemaStep.reduce(
  (mergedSchemas, schema) => mergedSchemas.concat(schema),
  yup.object()
)

/**
 *
 */
const getMetadata = values => {
  const metadata = { ...values }
  metadata.sellerFeeBasisPoints *= 100
  metadata.image = metadata.image.name
  metadata.properties.files = [
    {
      uri: metadata.image.name,
      type: values.image.type || "unknown",
    },
  ]

  return metadata
}

const Create = ({ data, pageContext }) => {
  const wallet = useWallet()
  const { localizedPath, locale, defaultLang } = useLocalization()
  const [user, dispatch] = useUser()
  const [nft, setNft] = React.useState()
  const [mintingError, setMintingError] = React.useState()
  const [step, setStep] = React.useState(0)
  const [progress, setProgress] = React.useState("")
  const { t } = useTranslation()
  const stickyRef = React.useRef()
  const [preview, setPreview] = React.useState(transparentPixel)

  const fallbackCollectionQuery = useQuery(
    `nft_create_get_collection_fallback`,
    () => {
      return getCollection(process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS)
    }
  )

  const collectionQuery = useQuery(`nft_create_get_collection`, () => {
    return getUserCollections(user.publicKey)
  })

  React.useEffect(() => {
    getBalance(user.publicKey)
      .then(balance => {
        dispatch({ type: "SET_BALANCE", balance })
      })
      .catch(err => {
        console.log(err)
      })
  }, [user.publicKey, dispatch])

  if (typeof window === "undefined") {
    // FileReader cant' be server side rendered
    return null
  }

  if (!user.loggedIn) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/login/`,
      })
    )
    return null
  }

  if (!user.data.is_artist) {
    return (
      <Layout {...{ pageContext }} $background="#f3f4fa">
        <BecomeAnArtist />
      </Layout>
    )
  }

  const reader = new FileReader()
  const isLastStep = step === validationSchemaStep.length - 1

  if (collectionQuery.isLoading || fallbackCollectionQuery.isLoading) {
    return (
      <Layout {...{ pageContext }} $background="#f3f4fa">
        <Loader />
      </Layout>
    )
  }

  const collections = [
    fallbackCollectionQuery.data,
    ...collectionQuery.data.filter(
      c => c.address !== process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS
    ),
  ]

  return (
    <Layout {...{ pageContext }} $background="#f3f4fa">
      <Section
        title={t("nft.createTitle")}
        subtitle={t("nft.createSubtitle")}
      />
      <Section>
        <Formik
          validationSchema={validationSchemaStep[step]}
          initialValues={{
            collectionAddress: process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS,
            collection: {
              family: "",
              name: "",
            },
            name: "",
            symbol: "",
            creators: [
              new Creator({
                address: user.publicKey,
                share: 100,
              }),
            ],
            description: "",
            sellerFeeBasisPoints: 10,
            image: "",
            animation_url: "",
            attributes: [],
            external_url: "",
            properties: {
              files: [],
              category: "image",
            },
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (!isLastStep && step < validationSchemaStep.length - 1) {
              window.scrollTo({ top: 0, behavior: "smooth" })
              setSubmitting(false)
              setStep(prev => prev + 1)
              return
            }

            // just to make sure everything is fine
            fullValidationSchema.validateSync(values)

            // go to the final step showing the loader
            setStep(prev => prev + 1)
            setProgress("")

            const metadata = getMetadata(values)
            let mintData = {}

            mintNFT(wallet, [values.image], metadata, setProgress, 1)
              .then(metadata => {
                mintData = metadata
                if (values.collectionAddress === "") {
                  return
                }

                return addNFtInCollection(values.collectionAddress, {
                  address: mintData.mintKey,
                  name: values.name,
                  symbol: values.symbol,
                  external_json_url: mintData.metadataUri,
                  description: values.description,
                  attributes: values.attributes,
                  creators_address: values.creators.map(c => c.address),
                  category: values.properties.category,
                  image_url: mintData.fileUri,
                  minted_on_nft4artists: 1,
                })
              })
              .then(metadata => {
                setNft(metadata)
              })
              .catch(err => {
                setMintingError(err?.message || t("unknownError"))
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
        >
          {({ values, isSubmitting, setFieldValue, setSubmitting }) => {
            const steps = [
              <CollectionStep
                {...{ values, collections, setFieldValue, setSubmitting }}
              />,
              <MainStep {...{ values }} />,
              <MediaStep {...{ setFieldValue, reader, setPreview, values }} />,
              <AttributesStep {...{ values }} />,
              <CreatorsStep {...{ values }} />,
              <ConfirmStep {...{ values }} />,
              <MintingStep
                {...{ progress, nft, mintingError, setMintingError, setStep }}
              />,
            ]

            const collection = collections.find(
              c => c.address === values.collectionAddress
            )

            return (
              <Form autoComplete="off" data-cy="create-nft-form">
                <GridContainer>
                  <div>
                    {steps[step]}
                    {step <= validationSchemaStep.length - 1 && (
                      <>
                        <p />
                        <Grid>
                          {step === 0 ? (
                            <div />
                          ) : (
                            <Button
                              disabled={isSubmitting}
                              onClick={() => {
                                window.scrollTo({ top: 0, behavior: "smooth" })
                                setStep(prev => Math.max(0, prev - 1))
                              }}
                              label={t("prev")}
                            />
                          )}
                          <Button
                            disabled={isSubmitting}
                            submit
                            data-cy="submit"
                            label={
                              isLastStep
                                ? t("nft.form.buttonCreate")
                                : t("next")
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </div>
                  <StickyContainer>
                    <Sticky>
                      {({ style, isSticky, distanceFromTop }) => (
                        <div
                          ref={stickyRef}
                          style={{
                            ...style,
                            paddingTop: isSticky
                              ? "100px"
                              : `${
                                  distanceFromTop < 100
                                    ? 100 - distanceFromTop
                                    : 0
                                }px`,
                          }}
                        >
                          <NftTeaser
                            name={
                              values.name || (
                                <PlaceHolder>
                                  {placeholder.repeat(10)}
                                </PlaceHolder>
                              )
                            }
                            artist={user.data.name}
                            image={
                              <Image
                                image={preview}
                                type="NFT"
                                avatar={
                                  typeof collection.icon_url !== "undefined" ? (
                                    <Avatar image={collection.icon_url} />
                                  ) : null
                                }
                              />
                            }
                          />
                        </div>
                      )}
                    </Sticky>
                  </StickyContainer>
                </GridContainer>
              </Form>
            )
          }}
        </Formik>
      </Section>
    </Layout>
  )
}

const CollectionStep = ({
  values,
  collections,
  setFieldValue,
  setSubmitting,
}) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    setSubmitting(true)
    if (
      values.collectionAddress === "" ||
      values.collectionAddress ===
        process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS
    ) {
      setFieldValue("collection.name", "")
      setFieldValue("collection.family", "")
      setFieldValue("symbol", "n4a")
      setFieldValue("external_url", "")
      if (values.attributes.length > 0) {
        setFieldValue("attributes", [])
      }
      setSubmitting(false)
      return
    }
    const collection = collections.find(
      c => c.address === values.collectionAddress
    )

    setFieldValue("collection.name", collection.name)
    setFieldValue("collection.family", collection.family)
    setFieldValue("symbol", collection.symbol)
    setFieldValue("external_url", collection.website)

    getNftsInCollection(collection.address)
      .then(response => {
        if (response.tokens.length === 0) {
          setSubmitting(false)
          return
        }

        const attributes = response.tokens.reduce((acc, token) => {
          const { attributes } = token
          for (let i = 0; i < attributes.length; i++) {
            if (!acc.includes(attributes[i].trait_type)) {
              acc.push(attributes[i].trait_type)
            }
          }
          return acc
        }, [])

        setFieldValue(
          "attributes",
          attributes.map(a => ({ trait_type: a, value: "" }))
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [
    values.collectionAddress,
    collections,
    setFieldValue,
    setSubmitting,
    values.attributes.length,
  ])

  return (
    <>
      <FormField title={t("nft.form.collection")} name="collectionAddress">
        <Field name="collectionAddress" component={Select}>
          {collections.map(collection => (
            <option key={collection.address} value={collection.address}>
              {collection.name}
            </option>
          ))}
        </Field>
      </FormField>
      <KeyValue
        indicator={t("nft.form.noCollectionYet")}
        variable={
          <Link to="/collection/create/">{t("nft.form.createCollection")}</Link>
        }
        size="large"
      />
    </>
  )
}

const MainStep = ({ values }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormField title={t("nft.form.name")} name="name">
        <Field name="name" component={Input} />
      </FormField>
      <FormField
        title={t("nft.form.symbol")}
        help={t("nft.form.symbol_help")}
        name="symbol"
      >
        <Field
          name="symbol"
          component={Input}
          readOnly={
            values.collection !== "" &&
            values.collection !== process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS
          }
        />
      </FormField>
      <FormField title={t("nft.form.description")} name="description">
        <Field name="description" component={TextArea} />
      </FormField>
    </>
  )
}

const MediaStep = ({ setPreview, reader, setFieldValue, values }) => {
  const { t } = useTranslation()
  return (
    <>
      <FormField
        title={t("nft.form.image")}
        help={t("nft.form.image_help")}
        name="image"
      >
        <FileInput
          name="image"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={e => {
            setFieldValue("image", e.currentTarget.files[0])
            reader.onload = () => {
              setPreview(reader.result)
            }
            reader.readAsDataURL(e.currentTarget.files[0])
          }}
        />
      </FormField>
      {values.properties.category !== "image" && (
        <FormField
          title={t("nft.form.animation_url")}
          help={t("nft.form.animation_url_help")}
          name="animation_url"
        >
          <Field name="animation_url" component={Input} />
        </FormField>
      )}
      <FormField
        title={t("nft.form.external_url")}
        help={t("nft.form.external_url_help")}
        name="external_url"
      >
        <Field name="external_url" component={Input} />
      </FormField>
    </>
  )
}

const AttributesStep = ({ values }) => {
  const { t } = useTranslation()

  return (
    <FieldArray
      name="attributes"
      render={arrayHelpers => (
        <FormField
          title={t("nft.form.attributes")}
          help={t("nft.form.attributes_help")}
        >
          <KeyValueGrid>
            <div>{t("nft.form.trait")}</div>
            <div>{t("nft.form.value")}</div>
            <div />
            {values.attributes.map((_, i) => (
              <React.Fragment key={i}>
                <FormField name={`attributes.${i}.trait_type`}>
                  <Field
                    name={`attributes.${i}.trait_type`}
                    component={Input}
                  />
                </FormField>
                <FormField name={`attributes.${i}.value`}>
                  <Field name={`attributes.${i}.value`} component={Input} />
                </FormField>
                <div>
                  <Button
                    data-cy={`remove-attribute-${i}`}
                    onClick={() => {
                      arrayHelpers.remove(i)
                    }}
                    label={t("nft.form.removeAttribute")}
                    small
                  />
                </div>
              </React.Fragment>
            ))}
          </KeyValueGrid>
          <Button
            data-cy="add-attribute"
            onClick={() => {
              arrayHelpers.push({
                trait_type: "",
                value: "",
              })
            }}
            label={t("nft.form.addAttribute")}
            small
          />
        </FormField>
      )}
    />
  )
}

const CreatorsStep = ({ values }) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldArray
        name="creators"
        render={arrayHelpers => (
          <FormField
            title={t("nft.form.creators")}
            help={t("nft.form.creators_help")}
            name="creators"
          >
            <KeyValueGrid>
              <div>{t("nft.form.address")}</div>
              <div>{t("nft.form.share")}</div>
              <div />
              {values.creators.map((_, i) => (
                <React.Fragment key={i}>
                  <FormField name={`creators.${i}.address`}>
                    <Field
                      name={`creators.${i}.address`}
                      component={Input}
                      readOnly={
                        i === 0
                      } /* The first creator is the always the current user */
                    />
                  </FormField>
                  <FormField name={`creators.${i}.share`}>
                    <Field name={`creators.${i}.share`} component={Input} />
                  </FormField>
                  <div>
                    {i > 0 && (
                      <Button
                        onClick={() => {
                          arrayHelpers.remove(i)
                        }}
                        data-cy={`remove-creator-${i}`}
                        label={t("nft.form.removeCreator")}
                        small
                      />
                    )}
                  </div>
                </React.Fragment>
              ))}
            </KeyValueGrid>
            {values.creators.length < 4 && (
              <Button
                onClick={() => {
                  arrayHelpers.push(
                    new Creator({
                      address: "",
                      share: 0,
                    })
                  )
                }}
                data-cy="add-creator"
                label={t("nft.form.addCreator")}
                small
              />
            )}
          </FormField>
        )}
      />

      <FormField
        title={t("nft.form.sellerFeeBasisPoints")}
        help={t("nft.form.sellerFeeBasisPoints_help")}
        name="sellerFeeBasisPoints"
      >
        <Field
          name="sellerFeeBasisPoints"
          type="number"
          min="0"
          max="100"
          component={Input}
        />
      </FormField>
    </>
  )
}

const ConfirmStep = ({ values }) => {
  const { t } = useTranslation()
  const [cost, setCost] = React.useState({
    total: "",
    files: "",
    rent: "",
  })

  React.useEffect(() => {
    getCosts([values.image], getMetadata(values))
      .then(cost => setCost(cost))
      .catch(err => console.log(err))
  }, [values])

  return (
    <>
      <p>{t("nft.form.transactionsInstructions")}</p>
      <KeyValue
        indicator={t("nft.form.totalEstimatedCost")}
        variable={cost.total + " SOL"}
        direction="column"
        size="large"
      />
    </>
  )
}

const MintingStep = ({
  progress,
  nft,
  mintingError,
  setMintingError,
  setStep,
}) => {
  const { t } = useTranslation()

  if (mintingError) {
    return (
      <Minting>
        <p>
          {t("nft.form.errorOccurred")}
          <br />({mintingError})
        </p>
        {progress}
        <Button
          onClick={() => {
            setStep(validationSchemaStep.length - 1)

            setMintingError(undefined)
          }}
          label={t("nft.form.tryAgain")}
        />
      </Minting>
    )
  }

  return (
    <Minting>
      {nft ? (
        <>
          <p>{t("nft.form.finished")}</p>
          <Button
            to={`/nft/${nft.address.toString()}`}
            label={t("nft.form.seeMint")}
          />
        </>
      ) : (
        <>
          <Loader messages={[`${Math.floor((100 / 9) * progress)}%`]} />
          <p>{t("nft.form.pleaseWait")}</p>
          <p>
            <b>{t("nft.form.doNotClose")}</b>
          </p>
        </>
      )}
    </Minting>
  )
}

const Minting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  text-align: center;
  align-self: center;
`

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 32px;
  grid-gap: 5px;
`

export default Create

export const createNftQuery = graphql`
  query createNftQuery {
    allTag {
      nodes {
        statamicId
        title
      }
    }
  }
`
